import React from "react"

import Url from "../../../lib/url"

export default (props) => {

  if (!props.children || !props.url) {
    throw new Error('invalid "button" configuration, missing "url" and/or "text" (children)');
  }

  let url = Url(props.url);
  let target = null;
  let rel = null;

  if (url.startsWith('http')) {
    // external url
    target = '_blank';
    rel = 'noopener noreferrer'
  }

  return (
      <a className={`btn ${props.className || ''}`} href={url} target={target} rel={rel}>{props.children}</a>
  );
}
