// extracted by mini-css-extract-plugin
export var header = "component-module--header--3ifG6";
export var light = "component-module--light--2IukE";
export var content = "component-module--content--1tvSM";
export var img = "component-module--img--4dFfS";
export var withImg = "component-module--with-img--2dE9l";
export var puzzle = "component-module--puzzle--1wZFG";
export var bigcross = "component-module--bigcross--24hnz";
export var smallcross = "component-module--smallcross--UqOhL";
export var text = "component-module--text--L_uM_";
export var breadcrumb = "component-module--breadcrumb--2zisp";