import React from "react"

import RemoteAssetUrl from "../../../lib/remote-asset-url"

import LinkBtn from "../../elements/link_button";

import * as styles from "./component.module.less"

export default (props) => {

  if (!props.image) {
    return (
        <div>invalid image_teaser configuration</div>
    );
  }

  const button = function(type, text, url) {
    if (type === null || type.length === 0 || url === null) {
      return null;
    }

    let className = '';
    let btnText = text;

    switch (type) {
      case 'Weiß':
        className = 'outline em';
        break;
      case 'Apple App Store':
        className = 'appStore';
        btnText = 'Apple App Store';
        break;
      case 'Google Play Store':
        className = 'playStore';
        btnText = 'Google Play Store';
        break;
      case 'Blau':
      default:
        className = 'block em';
    }

    if (btnText === null || btnText.length === 0) {
      return null;
    }

    return (<LinkBtn className={`${styles.btn} ${className}`} url={url}>{btnText}</LinkBtn>);
  };

  const imgSrc = RemoteAssetUrl(props.image.fluid.src);

  if (!props.headline && !props.text) {
    return (
        <img className={`${styles.imageTeaser} ${props.className || ''}`} src={imgSrc} alt={props.image.alt} title={props.image.title} />
    );
  }

  return (
    <div className={`${styles.imageTeaser} ${props.className || ''}`}>
      <div className={styles.img} style={{
        backgroundImage: 'url(' + imgSrc + ')'
      }} />

      <div className={styles.contentContainer}>
        <div className={`text ${styles.teaser}`}>
          <div className={styles.inner}>
            {props.headline &&
              <h1 dangerouslySetInnerHTML={{ __html: props.headline }} />
            }
            {props.text &&
              <div dangerouslySetInnerHTML={{ __html: props.text }} />
            }
            {props.button1text &&
              button(props.button1type,props.button1text, props.button1link)
            }
            {props.button2text &&
              button(props.button2type,props.button2text, props.button2link)
            }
          </div>
        </div>
      </div>

    </div>
  )
}
