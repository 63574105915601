import React from "react"

import Container from "../../content-element/container"

import * as styles from "./component.module.less"

export default (props) => (
    <Container id={props.anchor} className={`text ${styles.textContainer} ${props.className || ''}`} width={props.width}>
      {props.children}

      {props && props.text &&
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
      }
    </Container>
)
