export const pagerParamName = 'seite';

const indexPageSlug = 'index';

let navTreeIndex = null;
let rootPageIndex = null;

/**
 *
 *
 * @param {*[]} tree
 * @param {*[]} path
 */
const indexTree = (tree, path = []) => {
  if (navTreeIndex === null) {
    navTreeIndex = [];
  }

  if (tree === null) {
    return;
  }

  for (let branch of tree) {
    if (!branch || !branch.link || !branch.link.id) {
      continue;
    }

    navTreeIndex.push({
      internal: {
        type: (branch.link.internal.type) || null
      },
      id: branch.link.id,
      slug: branch.link.slug,
      title: branch.title,
      path: path
    });

    if (branch.treeChildren) {
      const branchPath = path.slice();
      const children = branch.treeChildren.slice();
      branch.treeChildren = null;

      branchPath.push(branch);
      indexTree(children, branchPath);
    }
  }
};

/**
 *
 *
 * @param rootPageConfig
 */
const indexRootPages = (rootPageConfig) => {
  if (rootPageIndex === null) {
    rootPageIndex = {};
  }

  if (rootPageConfig === null) {
    return;
  }

  const confKeys = Object.keys(rootPageConfig);
  for (let confKey of confKeys) {
    const rootPage = rootPageConfig[confKey];
    if (!rootPage['id'] || !rootPage['slug']) {
      continue;
    }

    let slug = rootPage.slug;
    if (rootPage.slug === indexPageSlug) {
      slug = '';
    }

    rootPageIndex[rootPage.id] = '/' + slug;
  }
};

/**
 *
 *
 * @param item
 * @return {Object[]}
 *
 * @private
 */
const findNavPath = (item) => {
  const leaf = navTreeIndex.find(l => l.id === item.id);
  return leaf !== undefined ? leaf.path.slice() : [];
};

/**
 *
 *
 * @param item
 * @return {Object}
 *
 * @private
 */
const findNavItem = (item) => {
  const leaf = navTreeIndex.find(l => l.id === item.id);
  return leaf !== undefined ? leaf : null;
};

/**
 *
 *
 * @param item
 * @param suffix
 * @param pagerPage
 * @param pathPrefixes
 *
 * @return {string}
 */
const url = (item, suffix = '', pagerPage = 0, pathPrefixes = null) => {
  if (!item) {
    throw new Error('pagePath builder url: !item');
  }

  // root pages precede
  if (rootPageIndex[item.id]) {
    return (rootPageIndex[item.id] + '/').replace(/\/\//, '/');
  }

  //

  let path = [];

  // get path in navigation

  if (pathPrefixes !== null && pathPrefixes[item.internal.type]) {
    // specific paths
    path.push(pathPrefixes[item.internal.type]);
  } else {
    // generic paths
    let navPath = findNavPath(item);

    // remove third segment if path length is "3" or greater
    if (navPath.length >= 3) {
      delete navPath[2];
    }

    // always remove first segment
    delete navPath[0];

    for (let navPathItem of navPath) {
      if (!navPathItem) {
        continue;
      }
      path.push(navPathItem.link.slug);
    }
  }

  // add item slug
  path.push(item.slug);

  // add suffix if available
  if (suffix.length > 0) {
    path.push(suffix);
  }

  // add pager parameter if necessary
  if (pagerPage > 1) {
    path.push(pagerParamName);
    path.push(pagerPage);
  }

  // build url
  return ('/' + path.join('/') + '/').replace(/\/\//, '/');
};

/**
 *
 *
 * @param {Object} item
 * @param {boolean} includeItem
 * @param {Object} pathPrefixes
 *
 * @return {[{name: *, url: string}]}
 */
const breadcrumb = (item, includeItem, pathPrefixes = null) => {
  if (!item) {
    throw new Error('pagePath builder breadcrumb: !item');
  }

  const navPath = findNavPath(item);

  const bc = navPath.map(p => {
    return {
      name: p.title,
      url: url(p.link, '', 0, pathPrefixes)
    };
  });

  if (!includeItem) {
    return bc;
  }

  const navItem = findNavItem(item);
  if (navItem !== null) {
    bc.push({
      name: navItem.title,
      url: url(item)
    });
  }

  return bc;
};

/**
 *
 *
 * @param tree
 * @param rootPageConfig
 * @param pathPrefixes
 *
 * @return {{breadcrumb: (function(*=, *=, *=): {name: *, url: string}[]), url: (function(*=, *=, *=): string)}}
 */
export const builder = (tree, rootPageConfig = null, pathPrefixes = null) => {
  const treeCopy = JSON.parse(JSON.stringify(tree));

  if (navTreeIndex === null) {
    indexTree(treeCopy);
  }

  if (rootPageIndex === null) {
    indexRootPages(rootPageConfig);
  }

  return {
    url: (item, suffix = '', pagerPage = 0) => {
      return url(item, suffix, pagerPage, pathPrefixes)
    },
    breadcrumb: (item, includeItem = false) => {
      return breadcrumb(item, includeItem, pathPrefixes)
    }
  };
};
