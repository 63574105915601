import React from "react"
import {graphql, StaticQuery} from "gatsby";

import * as pagePath from "../../lib/page-path";
import Tel from "../../lib/tel";

import NewsLetterForm from '../forms/newsletter'

import Container from "../content-element/container"

import MailServiceAction from "../elements/mail-service-action";

import * as styles from "./component.module.less"

export default (props) => (
    <StaticQuery
        query={graphql`
          query PageFooterQuery {
allDatoCmsNavigationMain(
  sort: {fields: position, order: ASC}, 
  filter: {root: {eq: true}}
) {
  nodes {
    link {
      ... on DatoCmsSeiten {
        id
        slug
        internal {
          type
        }
      }
    }
    treeChildren {
      link {
        ... on DatoCmsSeiten {
          id
          slug
          internal {
            type
          }
        }
      }
      treeChildren {
        link {
          ... on DatoCmsSeiten {
            id
            slug
            internal {
              type
            }
          }
        }
        treeChildren {
          link {
            ... on DatoCmsSeiten {
              id
              slug
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
}
allDatoCmsNavigationFooter(sort: {
  fields:position,
  order:ASC
}) {
  nodes {
    id
    title
    position
    link {
      ... on DatoCmsSeiten {
        id
        slug
        internal {
          type
        }
      }
      ... on DatoCmsLeistungsdatenbank {
        id
        slug
        internal {
          type
        }
      }
      ... on DatoCmsNews {
        id
        slug
        internal {
          type
        }
      }
      ... on DatoCmsArbeitgebernews {
        id
        slug
        internal {
          type
        }
      }
      ... on DatoCmsStellenangebote {
        id
        slug
        internal {
          type
        }
      }
    }
  }
}
allDatoCmsConfig {
  nodes {
    pageIndex {
      id
      name
      slug
    }
    becomeAMemberPage {
      id
      name
      slug
    }
    contactPage {
      id
      name
      slug
    }
    mainDepartment {
      telefon
      fax
      strasse
      hausnummer
      plz
      ort
    }
  }
}
site {
  siteMetadata {
    urlPaths {
      DatoCmsLeistungsdatenbank
      DatoCmsStellenangebote
      DatoCmsArbeitgebernews
      DatoCmsNews
    }
  }
}
          }
        `}
        render={data => {

          if (!props.page || !props.page.seo || !props.page.slug) {
            throw new Error('page footer: current page attributes missing');
          }

          const currentPage = props.page;
          const config = data.allDatoCmsConfig.nodes[0];

          const pathBuilder = pagePath.builder(
              data.allDatoCmsNavigationMain.nodes,
              config,
              data.site.siteMetadata.urlPaths
          );

          const navElements = data.allDatoCmsNavigationFooter.nodes;

          const year = (new Date()).getFullYear();

          return (
            <>
              {(props.disableAction === undefined || props.disableAction === false) &&
                <MailServiceAction />
              }

              <footer className={styles.pageFooter} data-datocms-noindex={''}>

                <Container className={styles.inner}>

                  <div className={styles.cols}>

                    <div className={styles.contact} itemScope itemType="http://schema.org/Organization">
                      <span className={`h2 ${styles.headline}`} itemProp="name">BKK Linde</span>

                      <address itemProp="address">
                        {config.mainDepartment.strasse} {config.mainDepartment.hausnummer}<br />
                        {config.mainDepartment.plz} {config.mainDepartment.ort}
                      </address>

                      {config.mainDepartment.telefon &&
                        <a className={`${styles.link} ${styles.phone}`} href={Tel(config.mainDepartment.telefon)} itemProp="telephone" content={Tel(config.mainDepartment.telefon, true)}>{config.mainDepartment.telefon}</a>
                      }
                      {config.mainDepartment.fax &&
                        <span className={styles.fax} itemProp="faxNumber" content={Tel(config.mainDepartment.fax, true)}>{ config.mainDepartment.fax }</span>
                      }
                    </div>

                    <div className={styles.footerMain}>
                      <nav className={styles.footerNav}>
                        <ol>
                          {navElements.map(el => (
                              <li key={el.id} className={el.link.slug === currentPage.slug ? styles.active : ''}>
                                <a href={pathBuilder.url(el.link)} className={styles.link}>{ el.title }</a>
                              </li>
                          ))}
                        </ol>
                      </nav>

                      <div className={styles.newsletter}>
                        <NewsLetterForm prefix={'nl-ft'} legend={'Abonnieren Sie jetzt unser Kundenmagazin'} btnText={'Abonnieren'} btnClass={'btn block'} subscriptionCategory={'kundenmagazin'} />
                      </div>
                    </div>

                  </div>

                </Container>

                <div className={styles.pageClose}>
                  <div className={styles.inner}>
                    &copy; Copyright { year } BKK Linde&nbsp;
                  </div>
                </div>

              </footer>
            </>
          )
        }}
    />
)
