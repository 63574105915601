export default (urlObj) => {

  // url from object with slug
  if (typeof urlObj === 'object') {
    if (!urlObj.slug) {
      throw new Error('invalid "url"');
    }

    return '/' + urlObj.slug + '/';
  }

  if (typeof urlObj !== 'string') {
    throw new Error('invalid "url"');
  }

  // url from string

  let url = urlObj;

  if (url.startsWith('http')) {
    // external url
    return url;
  }

  // internal url

  // should be an absolute path
  if (!url.startsWith('/')) {
    url = '/' + url;
  }

  // should end with trailing slash
  if (!url.endsWith('/')) {
    url = url + '/';
  }

  return url;
}
