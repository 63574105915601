import React from "react"
import {graphql, StaticQuery} from "gatsby";

import {Helmet} from "react-helmet";

import * as styles from "./component.module.less"

export default () => (
//     <StaticQuery
//         query={graphql`
// query MailActionFlagQuery {
//   site {
//     siteMetadata {
//       flags {
//         mailAction {
//           enabled
//           url
//         }
//       }
//     }
//   }
// }
//         `}
//         render={data => {
//           if (data.site.siteMetadata.flags.mailAction.enabled === false) {
//             return null;
//           }

//           return (
//               <>
//                 <Helmet>
//                   <script src="https://embed.typeform.com/embed.js" />
//                 </Helmet>
//                 <a className={`typeform-share ${styles.msAct}`} href={data.site.siteMetadata.flags.mailAction.url} data-mode="drawer_right" target="_blank" rel="noopener noreferrer">
//                   <span className={styles.title}>Digitaler Postservice</span>
//                   <span className={styles.info}>Feedback in 48 Stunden</span>
//                 </a>
//               </>
//           );
//         }}
//     />
<></>
);
