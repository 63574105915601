import React from "react"

import GlobalProvider from "../../components/context/global";

import PageHeader from "../../components/page-header"
import PageFooter from "../../components/page-footer"

import CmsHeader from '../../components/content-element/header';
import ImageTeaser from '../../components/content-element/image_teaser';

import WarningMessage from "../../components/elements/warning_message";

import * as styles from "./component.module.less"

export default (props) => {

  const hasHeader = props.page.header ?props.page.header.length > 0 : false;
  const shouldRenderHeader = props.ignoreHeader ? !props.ignoreHeader : true;

  const breadcrumb = props.breadcrumb || [];

  const warnMsgRef = React.createRef();

  return (
      <GlobalProvider>
        <WarningMessage ref={warnMsgRef} />
        <PageHeader page={props.page} warnMsgRef={warnMsgRef} />
        <main className={`${props.className || ''} ${props.darkBackground ? styles.dark : ''}`}>

          {/* default header */}
          {shouldRenderHeader && !hasHeader &&
            <CmsHeader
                lightTheme={false}
                title={props.page.name || null}
                breadcrumb={breadcrumb}
            />
          }

          {/* dynamic header */}
          {shouldRenderHeader && hasHeader && props.page.header.map(hel => {
            if (!hel || !hel.id || !hel.internal || !hel.internal.type) {
              return '';
            }

            switch (hel.internal.type) {
              // 1.01. Header weiße Textbox
              case 'DatoCmsHeaderTextbox':
                return (
                    <ImageTeaser
                        key={hel.id}
                        headline={hel.headline}
                        text={hel.subheadline}
                        image={hel.bild}
                        button1type={hel.button1Typ}
                        button1text={hel.button1Text}
                        button1link={hel.button1Link}
                        button2type={hel.button2Typ}
                        button2link={hel.button2Link}
                        button2text={hel.button2Text}
                    />
                );

              // 1.02. & 1.03  Header text/Bild
              case 'DatoCmsHeaderTextBild':
                let islight = hel.hintergrundfarbe === 'Weiß';
                let hasPuzzle = hel.hintergrundfarbe === 'Blau mit Puzzle';
                return (
                    <CmsHeader
                        key={hel.id}
                        lightTheme={islight}
                        puzzle={hasPuzzle}
                        title={hel.headline}
                        img={hel.bild.fluid.src}
                        alt={hel.bild.alt}
                        breadcrumb={breadcrumb}
                    >
                      <div dangerouslySetInnerHTML={{__html: hel.text}}/>
                    </CmsHeader>
                );

              // 1.04. Header blau mit Wahlkreuz
              case 'DatoCmsHeaderBlueCross':
                return (
                    <CmsHeader
                        key={hel.id}
                        lightTheme={false}
                        title={hel.headline}
                        bigCross={hel.crossSize}
                        breadcrumb={breadcrumb}
                    >
                      <div dangerouslySetInnerHTML={{__html: hel.subheadline}}/>
                    </CmsHeader>
                );

                // unknown element
              default:
                return (
                    <p key={hel.id} style={{color:'red',fontSize:'30px'}}>&lt;- It's dead, Jim. I don't know how to render the modular block type "{hel.internal.type}"</p>
                )
            }
          })}

          {props.children}
        </main>
        <PageFooter page={props.page} />
      </GlobalProvider>
  );
}
