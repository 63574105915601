import React, { Component } from "react"

import Hash from './../../../lib/hash';

import * as styles from './component.module.less'

const storageKey = 'warning-message';

class WarningMessageView extends Component {

  _storage = null;
  _message = null;
  _hash = null;

  constructor(props) {
    super(props);

    this._message = props.message;
    this._hash = Hash(props.message.title + props.message.text);

    this.state = {
      hidden: false,
      visible: false,
    };

    this.onCloseClick = this.onCloseClick.bind(this);
  }

  componentDidMount() {
    if (this.wasAcknowledged()) {
      return;
    }

    this.setState({
      visible: true,
    });
  }

  onCloseClick(e) {
    e.preventDefault();
    this.acknowledge();
  }

  toggleVisibility() {
    const hidden = !this.state.hidden;
    this.setState({
      hidden: hidden,
    });
  }

  //

  storage() {
    if (this._storage != null) {
      return this._storage.impl;
    }

    const avail = 'localStorage' in window && window['localStorage'] !== null;
    if (!avail) {
      this._storage = {impl: null};
      return null;
    }

    let s;
    try {
      s = window.localStorage;
    } catch(e) {
      this._storage = {impl: null};
      return null;
    }

    this._storage = {impl: s};
    return s;
  }

  wasAcknowledged() {
    const storage = this.storage();
    if (storage == null) {
      return false;
    }

    let s;
    try {
      s = storage.getItem(storageKey);
    } catch (e) {
      return false;
    }

    if (s == null) {
      return false;
    }

    if (s !== this._hash) {
      try {
        storage.removeItem(storageKey);
      } catch (e) {}
      return false;
    }

    return true;
  }

  acknowledge() {
    this.setState({
      visible: false,
    });

    const storage = this.storage();
    if (storage == null) {
      return;
    }

    try {
      storage.setItem(storageKey, this._hash);
    } catch (e) {}
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    return (
        <div className={`${styles.warnMsg} ${this.props.compact === true ? styles.compact : ''} ${this.state.hidden ? styles.hidden : ''}`}>
          <div className={styles.inner}>
            <p className={styles.title}>{this._message.title}</p>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: this._message.text }} />
          </div>

          <button onClick={this.onCloseClick} className={`btn icon icon-close-b ${styles.close}`}>
            <span>Meldung schliessen</span>
          </button>
        </div>
    );
  }
}

export default WarningMessageView;
