import React from "react"

import RemoteAssetUrl from "../../../lib/remote-asset-url"

import Breadcrumb from "../../elements/breadcrumb";

import * as styles from "./component.module.less"

export default (props) => {

  const lightTheme = props.lightTheme || false;
  const puzzle = props.puzzle || false;
  const hasCross = !props.img && !lightTheme && props.bigCross && props.bigCross !== '';
  const bigCross = props.bigCross === "groß";

  return (
      <div className={`${styles.header} ${props.className || ''} ${lightTheme ? styles.light : ''} ${props.img ? styles.withImg : ''} ${hasCross && !bigCross ? styles.smallcross : ''} ${hasCross && bigCross ? styles.bigcross : ''}`}>

        <div className={`${styles.content} ${puzzle ? styles.puzzle : ''}`}>
          {props.breadcrumb &&
            <Breadcrumb className={styles.breadcrumb} items={props.breadcrumb} lightTheme={!lightTheme} />
          }

          {props.title &&
            <h1 className={`h1`} dangerouslySetInnerHTML={{ __html: props.title }} />
          }

          {props.children &&
            <div className={styles.text}>
              {props.children}
            </div>
          }
        </div>

        {props.img &&
          <div className={styles.img}>
            <img src={RemoteAssetUrl(props.img)} alt={props.alt || props.title || ''} />
          </div>
        }

      </div>
  )
}
