import React from "react"

import * as styles from "./component.module.less"

class NewsletterForm extends React.Component {

  mwSetupId = null;

  constructor(props) {
    super(props);

    if (!props.prefix || !props.subscriptionCategory) {
      throw new Error('invalid newsletter form configuration, missing "prefix" and/or "subscriptionCategory"');
    }

    switch (props.subscriptionCategory.toLowerCase().trim()) {
      case 'kundenmagazin':
        this.mwSetupId = 7;
        break;
      case 'arbeitgeber':
        this.mwSetupId = 3;
        break;
      default:
        throw new Error('invalid newsletter subscription category');
    }
  }

  componentDidMount() {
    const elId = this.props.prefix + '-fa_4';
    setInterval(function(){
      const el = document.getElementById(elId);
      if(el){
        if (isNaN(parseInt(el.value)) === true){
          el.value = 0;
        } else{
          el.value = parseInt(el.value) + 17;
        }
      }
    }, 1000);
  }

  render() {
    return (
        <form className={`${styles.form} ${this.props.className || ''}`} action="https://newsletter.bkk-linde.de/optin/optin/execute" method="post" acceptCharset="utf-8">

          <input name="account_id" value="4538" type="hidden" />
          <input name="account_code" value="4Wf0j" type="hidden" />
          <input name="optinsetup_id" value={this.mwSetupId} type="hidden" />
          <input name="optinsetup_code" value="grmpN" type="hidden" />
          <input id={this.props.prefix + '-fa_4'} name="ic" value="" type="hidden" />

          <fieldset>
            <legend>
              {this.props.legend &&
                <>{this.props.legend}</>
              }
              {!this.props.legend &&
                <>Melden Sie sich für unseren Newsletter an</>
              }
            </legend>

            <section>
              <label htmlFor={this.props.prefix + '-nl-email'}>E-Mail Adresse</label>
              <input
                  name="fields[1]"
                  id={this.props.prefix + '-nl-email'}
                  type="email"
                  placeholder="Geben Sie bitte Ihre E-Mail Adresse ein"
              />

              <button type="submit" className={`${this.props.btnClass || 'btn block'} submit-btn`}>
                {this.props.btnText &&
                  <>{this.props.btnText}</>
                }
                {!this.props.btnText &&
                  <>Newsletter abonnieren</>
                }
              </button>
            </section>
          </fieldset>
        </form>
    )
  }
}

export default NewsletterForm;
