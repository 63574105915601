// extracted by mini-css-extract-plugin
export var pageFooter = "component-module--pageFooter--3OTtC";
export var inner = "component-module--inner--1uR6f";
export var link = "component-module--link--BW64q";
export var headline = "component-module--headline--1JKC0";
export var cols = "component-module--cols--wp8NI";
export var contact = "component-module--contact--2awTF";
export var phone = "component-module--phone--1r0BB";
export var fax = "component-module--fax--3fhk9";
export var footerMain = "component-module--footer-main--3zgUS";
export var footerNav = "component-module--footer-nav--1XyjA";
export var newsletter = "component-module--newsletter--216X9";
export var active = "component-module--active--3Qprc";
export var pageClose = "component-module--page-close--2BnSd";