import React from "react"

export default (props) => (
    <form className={props.className || ''} action={'/suchergebnis/'}>
      {/*}
      <label htmlFor={(props.prefix || 'search') + '-input'}>{props.label || 'Suchbegriff'}</label>
      {*/}
      {props.autofocus &&
        <>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <input autoFocus id={(props.prefix || 'search') + '-input'} type={'search'} name={'s'} placeholder={props.placeholder || 'Suchbegriff'} />
        </>
      }
      {!props.autofocus &&
        <input id={(props.prefix || 'search') + '-input'} type={'search'} name={'s'} placeholder={props.placeholder || 'Suchbegriff'} />
      }
      <button type={'submit'} className={props.buttonClassName || ''}>
        {props.buttonLabel || 'Suchen'}
      </button>
    </form>
)
