import React from "react"

import * as styles from "./component.module.less"

export default (props) => {

  const items = props.items || [];

  let containerClass = '';
  if (props.className) {
    containerClass = props.className;
  }

  let themeClass = '';
  if (props.lightTheme) {
    themeClass = styles.light;
  }

  if (items.length === 0) {
    return null;
  }

  return (
      <ol className={`${styles.breadcrumb} ${themeClass} ${containerClass}`}>
        <li>
          <a href={'/'}><span className={'visually-hidden'}>Startseite</span></a>
        </li>
        {items.map((item, i) => (
            <li key={'breadcrumb-' + i}>
              <a href={item.url}>{item.name}</a>
            </li>
        ))}
      </ol>
  )
}
