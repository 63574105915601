import React from "react";

import * as styles from "./component.module.less";

const mainMenuAnimationDuration = 175; //ms

class MobileNav extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      animation: null,
      parentItems: [],
      openItem: {
        treeChildren: props.items
      },
      level: 0,
    };

    this.onToggleClick = this.onToggleClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
  }

  onToggleClick() {
    const open = !this.state.open;
    this.setState(() => ({
      open: open
    }));

    if (this.props.headerRef.current) {
      if (open) {
        this.props.headerRef.current.classList.add(styles.headerFixed);
      } else {
        this.props.headerRef.current.classList.remove(styles.headerFixed);
      }
    }

    if (this.props.warnMsgRef.current) {
      this.props.warnMsgRef.current.toggleVisibility();
    }
  }

  onItemClick(e, item) {
    if (item.treeChildren.length === 0) {
      // navigate to page
      return;
    }

    // navigate to level
    e.preventDefault();

    this.setState(() => ({
      animation: styles.offLeft
    }));

    setTimeout(() => {
      const parentItems = this.state.parentItems;
      parentItems.push(this.state.openItem);

      this.setState(oldState => ({
        animation: styles.inRight,
        parentItems: parentItems,
        openItem: item,
        level: oldState.level + 1
      }));

      setTimeout(() => {
        this.setState(() => ({
          animation: null
        }));
      }, mainMenuAnimationDuration);
    }, mainMenuAnimationDuration);
  }

  onBackClick(e) {
    e.preventDefault();

    this.setState(() => ({
      animation: styles.offRight
    }));

    setTimeout(() => {
      const openItem = this.state.parentItems.reverse()[0];
      const parentItems = this.state.parentItems.reverse().slice(1).reverse();

      this.setState(oldState => ({
        animation: styles.inLeft,
        parentItems: parentItems,
        openItem: openItem,
        level: oldState.level - 1
      }));

      setTimeout(() => {
        this.setState(() => ({
          animation: null
        }));
      }, mainMenuAnimationDuration);
    }, mainMenuAnimationDuration);
  }

  render() {
    return (
      <>
        <button className={styles.search} ref={this.props.searchTriggerRef} />
        <button className={`${styles.toggle} ${this.state.open ? styles.open : ''}`} onClick={this.onToggleClick}>Menü</button>
        {this.state.open &&
          <div className={styles.overlay}>
            <div className={styles.inner}>
              <div className={styles.main}>
                <ol className={`${styles.main} ${this.state.animation || ''}`}>
                  {this.state.level > 0 &&
                    <li className={styles.back}>
                      <button onClick={this.onBackClick}>zurück</button>
                    </li>
                  }
                  {this.state.openItem.link &&
                    <li className={styles.parent}>
                      <a href={this.state.openItem.urlPath}>{this.state.openItem.title}</a>
                    </li>
                  }
                  {this.state.openItem.treeChildren.map(el => (
                    <li key={el.id} className={`${this.state.level > 0 ? styles.l1 : ''} ${el.treeChildren.length > 0 ? styles.hasChild : ''}`}>
                      <a href={el.urlPath} onClick={(e) => {
                        this.onItemClick(e, el);
                      }}>{el.title}</a>
                    </li>
                  ))}
                </ol>
              </div>
              <div className={styles.aside}>
                {this.props.children}
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default MobileNav;
