import React, {Component} from "react";

import Form from "../../forms/search"

import * as styles from "./component.module.less";

class SearchGlobal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      isBeingRemoved: false
    };

    this.onShowClick = this.onShowClick.bind(this);
    this.onRemoveOverlayClick = this.onRemoveOverlayClick.bind(this);
  }

  componentDidMount() {
    for (let t of this.props.triggers) {
      if (t.current) {
        t.current.onclick = this.onShowClick;
      }
    }
  }

  onRemoveOverlayClick() {
    this.setState(() => ({
      isBeingRemoved: true
    }));

    setTimeout(() => {
      this.setState(() => ({
        active: false,
        isBeingRemoved: false
      }));
    }, 500);
  }

  onShowClick(e) {
    e.preventDefault();

    this.setState(() => ({
      active: true,
      isBeingRemoved: false
    }));
  }

  render() {
    return (
        <div className={`${styles.searchGlobal} ${this.props.className}`}>
          {this.state.active &&
            <div className={`${styles.overlay} ${this.state.isBeingRemoved ? styles.remove : ''}`}>
              <button className={`${styles.close} btn block inverted icon icon-close`} onClick={this.onRemoveOverlayClick}>Suche schliessen</button>

              <Form className={`${styles.form} elementary light`} prefix={'global'} placeholder={this.props.inputPlaceholder} autofocus={true} />
            </div>
          }
        </div>
    );
  }
}

export default SearchGlobal;
