import React, { Component } from "react"
import {graphql, StaticQuery} from "gatsby";

import View from './view';

class WarningMessage extends Component {

  viewRef;

  constructor(props) {
    super(props);

    this.viewRef = React.createRef();
  }

  toggleVisibility() {
    if (!this.viewRef.current) {
      return;
    }

    this.viewRef.current.toggleVisibility();
  }

  render() {
    return (
        <StaticQuery
            query={graphql`
              query WarningMessageQuery {
                allDatoCmsWarnmeldung {
                  nodes {
                    text
                    title
                  }
                }
              }
            `}
            render={data => {
              if (data.allDatoCmsWarnmeldung.nodes.length === 0) {
                return null;
              }

              return (<View compact={this.props.compact || false} ref={this.viewRef} message={data.allDatoCmsWarnmeldung.nodes[0]} />);
            }}
        />
    );
  }
}

export default WarningMessage;
