import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import * as pagePath from "../../lib/page-path"
import Tel from "../../lib/tel"

import HtmlHead from "../html-head"

import MobileNav from "./mobile-nav"
import SearchGlobal from "./search-global"

import * as styles from "./component.module.less"

export default props => (
  <></>
  // <StaticQuery
  //   query={graphql`
  //     query PageHeaderQuery {
  //       allDatoCmsNavigationMain(
  //         sort: { fields: position, order: ASC }
  //         filter: { root: { eq: true } }
  //       ) {
  //         nodes {
  //           id
  //           title
  //           link {
  //             ... on DatoCmsSeiten {
  //               id
  //               slug
  //               internal {
  //                 type
  //               }
  //             }
  //             ... on DatoCmsLeistungsdatenbank {
  //               id
  //               slug
  //               internal {
  //                 type
  //               }
  //             }
  //             ... on DatoCmsNews {
  //               id
  //               slug
  //               internal {
  //                 type
  //               }
  //             }
  //             ... on DatoCmsArbeitgebernews {
  //               id
  //               slug
  //               internal {
  //                 type
  //               }
  //             }
  //             ... on DatoCmsStellenangebote {
  //               id
  //               slug
  //               internal {
  //                 type
  //               }
  //             }
  //           }
  //           treeChildren {
  //             id
  //             title
  //             link {
  //               ... on DatoCmsSeiten {
  //                 id
  //                 slug
  //                 internal {
  //                   type
  //                 }
  //               }
  //               ... on DatoCmsLeistungsdatenbank {
  //                 id
  //                 slug
  //                 internal {
  //                   type
  //                 }
  //               }
  //               ... on DatoCmsNews {
  //                 id
  //                 slug
  //                 internal {
  //                   type
  //                 }
  //               }
  //               ... on DatoCmsArbeitgebernews {
  //                 id
  //                 slug
  //                 internal {
  //                   type
  //                 }
  //               }
  //               ... on DatoCmsStellenangebote {
  //                 id
  //                 slug
  //                 internal {
  //                   type
  //                 }
  //               }
  //             }
  //             treeChildren {
  //               id
  //               title
  //               link {
  //                 ... on DatoCmsSeiten {
  //                   id
  //                   slug
  //                   internal {
  //                     type
  //                   }
  //                 }
  //                 ... on DatoCmsLeistungsdatenbank {
  //                   id
  //                   slug
  //                   internal {
  //                     type
  //                   }
  //                 }
  //                 ... on DatoCmsNews {
  //                   id
  //                   slug
  //                   internal {
  //                     type
  //                   }
  //                 }
  //                 ... on DatoCmsArbeitgebernews {
  //                   id
  //                   slug
  //                   internal {
  //                     type
  //                   }
  //                 }
  //                 ... on DatoCmsStellenangebote {
  //                   id
  //                   slug
  //                   internal {
  //                     type
  //                   }
  //                 }
  //               }
  //               treeChildren {
  //                 id
  //                 title
  //                 link {
  //                   ... on DatoCmsSeiten {
  //                     id
  //                     slug
  //                     internal {
  //                       type
  //                     }
  //                   }
  //                   ... on DatoCmsLeistungsdatenbank {
  //                     id
  //                     slug
  //                     internal {
  //                       type
  //                     }
  //                   }
  //                   ... on DatoCmsNews {
  //                     id
  //                     slug
  //                     internal {
  //                       type
  //                     }
  //                   }
  //                   ... on DatoCmsArbeitgebernews {
  //                     id
  //                     slug
  //                     internal {
  //                       type
  //                     }
  //                   }
  //                   ... on DatoCmsStellenangebote {
  //                     id
  //                     slug
  //                     internal {
  //                       type
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //       allDatoCmsConfig {
  //         nodes {
  //           pageIndex {
  //             id
  //             name
  //             slug
  //           }
  //           becomeAMemberPage {
  //             id
  //             name
  //             slug
  //           }
  //           contactPage {
  //             id
  //             name
  //             slug
  //           }
  //           mainDepartment {
  //             telefon
  //           }
  //         }
  //       }
  //       allDatoCmsTemplateTexte {
  //         nodes {
  //           labelStichwortsucheGesamteSeite
  //         }
  //       }
  //       site {
  //         siteMetadata {
  //           urlPaths {
  //             DatoCmsLeistungsdatenbank
  //             DatoCmsStellenangebote
  //             DatoCmsArbeitgebernews
  //             DatoCmsNews
  //           }
  //           flags {
  //             login
  //           }
  //         }
  //       }
  //       allDatoCmsGeschaeftsstellen {
  //         nodes {
  //           besuchMitTerminMoeglich
  //         }
  //       }
  //     }
  //   `}
  //   render={data => {
  //     if (!props.page || !props.page.seo || !props.page.slug) {
  //       throw new Error("page header: current page attributes missing")
  //     }

  //     const flags = data.site.siteMetadata.flags
  //     const currentPage = props.page
  //     const config = data.allDatoCmsConfig.nodes[0]

  //     const pathBuilder = pagePath.builder(
  //       data.allDatoCmsNavigationMain.nodes,
  //       config,
  //       data.site.siteMetadata.urlPaths
  //     )

  //     const navElements = data.allDatoCmsNavigationMain.nodes.map(el => {
  //       el.urlPath = pathBuilder.url(el.link)
  //       el.active =
  //         el.link.slug === currentPage.slug ||
  //         el.treeChildren.find(childEl => {
  //           return childEl.link.slug === currentPage.slug
  //         }) != null

  //       el.treeChildren = el.treeChildren.map(childEl => {
  //         childEl.urlPath = pathBuilder.url(childEl.link)
  //         childEl.active = childEl.link.slug === currentPage.slug
  //         return childEl
  //       })

  //       return el
  //     })

  //     const mobileNavElements = JSON.parse(JSON.stringify(navElements))

  //     const searchOverlayTriggers = [React.createRef(), React.createRef()]
  //     const headerRef = React.createRef()

  //     return (
  //       <>
  //         <HtmlHead page={currentPage} />

  //         <div className={styles.pageHeader}>
  //           <header
  //             className={styles.header}
  //             ref={headerRef}
  //             data-datocms-noindex={""}
  //           >
  //             <a href={"/"} className={styles.logo}>
  //               BKK Linde - Die gute Wahl
  //             </a>

  //             <nav className={styles.mainNav}>
  //               <ol className={styles.l0}>
  //                 {navElements.map((el, i) => (
  //                   <li
  //                     key={el.id}
  //                     className={`${el.active ? styles.active : ""} ${
  //                       styles.l0
  //                     } ${styles["c" + el.treeChildren.length]}`}
  //                   >
  //                     <div className={styles.inner}>
  //                       <a href={el.urlPath} className={styles.link}>
  //                         <span>{el.title}</span>
  //                       </a>

  //                       {el.treeChildren.length > 0 && (
  //                         <ol className={`${styles.sub} ${"e" + i}`}>
  //                           {el.treeChildren.map(childEl => (
  //                             <li
  //                               key={childEl.id}
  //                               className={childEl.active ? styles.active : ""}
  //                             >
  //                               <a
  //                                 href={childEl.urlPath}
  //                                 className={styles.link}
  //                               >
  //                                 {childEl.title}
  //                               </a>
  //                             </li>
  //                           ))}
  //                         </ol>
  //                       )}
  //                     </div>
  //                   </li>
  //                 ))}
  //               </ol>
  //             </nav>

  //             <div className={styles.mobNavContainer}>
  //               <div className={styles.inner}>
  //                 <MobileNav
  //                   items={mobileNavElements}
  //                   searchTriggerRef={searchOverlayTriggers[1]}
  //                   headerRef={headerRef}
  //                   warnMsgRef={props.warnMsgRef}
  //                 >
  //                   {flags.login && (
  //                     <a
  //                       href={"/login"}
  //                       className={`${styles.iconLink} ${styles.login}`}
  //                     >
  //                       Login
  //                     </a>
  //                   )}
  //                   <a
  //                     className={`${styles.iconLink} ${styles.phone}`}
  //                     href={Tel(config.mainDepartment.telefon)}
  //                   >
  //                     Phone
  //                   </a>

  //                   {data.allDatoCmsGeschaeftsstellen.nodes.filter(
  //                     f => f.besuchMitTerminMoeglich === true
  //                   ).length > 0 && (
  //                     <Link
  //                       to={"/" + config.contactPage.slug + "/#cnm"}
  //                       className={`${styles.iconLink} ${styles.cnm} ${styles.link}`}
  //                     >
  //                       Click & Meet
  //                     </Link>
  //                   )}
  //                   <section>
  //                     <a
  //                       href={"/" + config.becomeAMemberPage.slug + "/"}
  //                       className="btn block"
  //                     >
  //                       {config.becomeAMemberPage.name}
  //                     </a>
  //                     <a
  //                       href={"/" + config.contactPage.slug + "/"}
  //                       className="btn outline"
  //                     >
  //                       {config.contactPage.name}
  //                     </a>
  //                   </section>
  //                 </MobileNav>
  //               </div>
  //             </div>

  //             <nav className={styles.toolNav}>
  //               <ul className={styles.iconNav}>
  //                 {data.allDatoCmsGeschaeftsstellen.nodes.filter(
  //                   f => f.besuchMitTerminMoeglich === true
  //                 ).length > 0 && (
  //                   <li>
  //                     <Link
  //                       to={"/" + config.contactPage.slug + "/#cnm"}
  //                       className={`${styles.iconLink} ${styles.cnm} ${styles.link}`}
  //                     >
  //                       Click & Meet
  //                     </Link>
  //                   </li>
  //                 )}
  //                 <li>
  //                   <button
  //                     className={`${styles.iconLink} ${styles.search} ${styles.link}`}
  //                     ref={searchOverlayTriggers[0]}
  //                   >
  //                     Suche
  //                   </button>
  //                 </li>
  //                 {flags.login && (
  //                   <li>
  //                     <a
  //                       href={"/login"}
  //                       className={`${styles.iconLink} ${styles.login} ${styles.link}`}
  //                     >
  //                       Login
  //                     </a>
  //                   </li>
  //                 )}
  //                 <li>
  //                   <a
  //                     className={`${styles.iconLink} ${styles.phone} ${styles.link}`}
  //                     href={Tel(config.mainDepartment.telefon)}
  //                   >
  //                     Phone
  //                   </a>
  //                 </li>
  //               </ul>

  //               <ul className={styles.btnNav}>
  //                 <li>
  //                   <a
  //                     href={"/" + config.becomeAMemberPage.slug + "/"}
  //                     className="btn block"
  //                   >
  //                     {config.becomeAMemberPage.name}
  //                   </a>
  //                 </li>
  //                 <li>
  //                   <a
  //                     href={"/" + config.contactPage.slug + "/"}
  //                     className="btn outline"
  //                   >
  //                     {config.contactPage.name}
  //                   </a>
  //                 </li>
  //               </ul>

  //               <SearchGlobal
  //                 className={styles.search}
  //                 linkClassName={styles.link}
  //                 inputPlaceholder={
  //                   data.allDatoCmsTemplateTexte.nodes[0]
  //                     .labelStichwortsucheGesamteSeite
  //                 }
  //                 triggers={searchOverlayTriggers}
  //               />
  //             </nav>
  //           </header>
  //         </div>
  //       </>
  //     )
  //   }}
  // />
)
